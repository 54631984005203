import React from "react";

function Validate() {
  return (
    <div className="signIn">
      <div>کاربر گرامی ثبت نام شما تکمیل شده</div>
      <div>در صورت تایید از طریق sms اطلاع رسانی خواهد شد</div>
    </div>
  );
}

export default Validate;
