import React, { useState, useEffect } from "react";

import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";

import { fetchApi, token, sliceNumber } from "../../defz";
import { useHistory } from "react-router-dom";

function Order() {
  const history = useHistory();
  const [order, setOrder] = useState("");

  const productHandler = (e) => {
    e.preventDefault();
    history.push(`/product/${e.target.id}`);
  };

  useEffect(() => {
    fetchApi("/api/shop/get_order", token, {
      number: 50,
    }).then((res) => {
      setOrder(res.data);
    });
  }, []);

  return (
    <div className="category">
      <Header name="سفارشات" />
      <main className="body">
        <div className="cats">
          {order
            ? order.map((item, index) => {
                return (
                  <div className="cat" key={index}>
                    <div className="badge">{item.product_list.length}</div>
                    <div className="img">
                      <div>
                        <span>
                          مبلغ سفارش : {sliceNumber(item.total_mony)} تومان
                        </span>
                        <span>نام خریدار : {item.username}</span>
                        <span>
                          تاریخ :{" "}
                          {item.dateTime.substr(0, 10).split("-").join("/")}
                        </span>
                      </div>
                    </div>
                    <span>
                      <div
                        className="see"
                        id={item._id}
                        onClick={productHandler}
                      >
                        مشاهده
                      </div>
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </main>
      <Navigation active="orderS" />
    </div>
  );
}

export default Order;
