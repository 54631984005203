import React, { useState, useEffect } from "react";

import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";

import user from "../../assets/Icons/user.svg";
import phoneNumber from "../../assets/Icons/phonenumber.svg";

import { fetchApi, sliceNumber } from "../../defz";
import { useParams } from "react-router-dom";

function Order() {
  let token = localStorage.getItem('ST');
  const [order, setOrder] = useState("");

  const { id } = useParams();

  useEffect(() => {
    fetchApi("/api/shop/get_order", token, {
      number: 50,
    }).then((res) => {
      res.data.map((item) => {
        if (item._id === id) {
          setOrder(res.data);
        }
        return null;
      });
    });
  }, [id]);

  return (
    <div className="category cat-product">
      <Header name="جزئیات سفارش" />
      <main className="body">
        <div className="cats">
          {order
            ? order.map((item, index) => {
                return (
                  <div className="cat" key={index}>
                    <div className="img">
                      <div>
                        <span className="title">مشخصات سفارش دهنده</span>
                        <span>
                          <img src={user} alt="user" /> : {item.username}
                        </span>
                        <span>
                          <img src={phoneNumber} alt="phoneNumber" /> :{" "}
                          <a href={"tel:" + item.phone_number}>
                            {item.phone_number}
                          </a>
                        </span>
                      </div>
                    </div>
                    <hr />
                    {item.product_list.map((product, index) => {
                      return (
                        <div className="product" key={index}>
                          <span className="title">مشخصات محصول</span>
                          <span>نام محصول : {product.name}</span>
                          <span>
                            قیمت واحد : {sliceNumber(product.mony_one)} تومان
                          </span>
                          <span>
                            قیمت با تخفیف : {sliceNumber(product.mony_total)}{" "}
                            تومان
                          </span>
                          <span>تعداد : {product.count}</span>
                        </div>
                      );
                    })}
                    <hr />
                    <div className="total-money">
                      قیمت کل : {sliceNumber(item.total_mony)} تومان
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </main>
      <Navigation active="orderS" />
    </div>
  );
}

export default Order;
