const mainAddress = `https://evimv2.liara.run/`;

export let token = localStorage.getItem('ST');

export const fetchApi = async (url, token, body) => {
  let lastResult;
  try {
    if (body) {
      await fetch(`${mainAddress}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Token': token
        },
        body: JSON.stringify(body),
      })
        .then(respone => respone.json())
        .then(result => lastResult = result)
        .catch(error => lastResult = error);
    } else {
      await fetch(`${mainAddress}/${url}`, {
        method: 'GET',
        headers: {
          'Auth-Token': token
        },
      })
        .then(respone => respone.json())
        .then(result => { lastResult = result });
    }

  } catch (error) {
    console.log(error);
  };
  return lastResult;
};

export const sliceNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}