import React from "react";
import "./Navigation.scss";

import category from "../../assets/Icons/Category.svg";
import product from "../../assets/Icons/Product.svg";
import order from "../../assets/Icons/Order.svg";

import categorySelect from "../../assets/Icons/CategorySelect.svg";
import productSelect from "../../assets/Icons/ProductSelect.svg";
import OrderSelect from "../../assets/Icons/OrderSelect.svg";

import { Link } from "react-router-dom";

function Navigation({ active }) {
  return (
    <div className="navigation">
      <Link to="/order">
        <img
          className={active === "orderS" ? "categorySelect" : "category"}
          src={active === "orderS" ? OrderSelect : order}
          alt="order"
        />
      </Link>
      <Link to="/category">
        <img
          className={active === "categoryS" ? "categorySelect" : "category"}
          src={active === "categoryS" ? categorySelect : category}
          alt="category"
        />
      </Link>
      <Link to="/products">
        <img
          className={active === "productS" ? "categorySelect" : "product"}
          src={active === "productS" ? productSelect : product}
          alt="product"
        />
      </Link>
    </div>
  );
}

export default Navigation;
