import './App.css';
import 'normalize.css';

import { Switch, Route } from 'react-router-dom';

import SignIn from './pages/SignIn/SignIn';
import Products from './pages/Products/Products';
import Product from './pages/Product/Product';
import Category from './pages/Category/Category';
import Validate from './pages/Validate/Validate';
import Order from './pages/Order/Order';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path='/' component={props => <SignIn />} />
        <Route path='/category' component={props => <Category />} />
        <Route path='/validate' component={props => <Validate />} />
        <Route path='/products' component={props => <Products />} />
        <Route path='/product/:id' component={props => <Product />} />
        <Route path='/order' component={props => <Order />} />
      </Switch>
    </div>
  );
}

export default App;
