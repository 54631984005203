import React, { useEffect } from "react";
import "./SignIn.scss";

import { useHistory } from "react-router-dom";

import { fetchApi } from "../../defz";

function SignIn() {
  const history = useHistory();

  const url = window.location.href;
  const token = url.slice(url.indexOf("?") + 1);

  useEffect(() => {
    fetchApi("/api/shop/fetchMyAccount", token).then((res) => {
      console.log(res);
      if (res.description === "Access denied!") {
        window.location.href = "https://evimmelk.com/webapp/shop-auth.html";
      } else if (res.validate === false) {
        history.push("/validate");
      } else {
        localStorage.setItem("ST", token);
        history.push("/category");
      }
    });
  }, [token, history]);

  return (
    <div className="signIn">
      <form action="">
        <div className="header">
          <span>اویم ملک ||شاغلین</span>
        </div>
        <div className="input"></div>
        <div className="title">در حال ارتباط با سرور</div>
        <div className="loader"></div>
      </form>
    </div>
  );
}

export default SignIn;
