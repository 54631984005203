import React from "react";
import "./Header.scss";

import arrow from "../../assets/Icons/Arrow.svg";

import { useHistory } from "react-router-dom";

function Header({ name }) {
  let history = useHistory();

  const back = () => {
    history.goBack();
  };

  return (
    <div className="header-components">
      <div className="content">{name}</div>
      <div>
        <img src={arrow} alt="arrow" onClick={back} />
      </div>
    </div>
  );
}

export default Header;
