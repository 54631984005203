import React, { useState, useEffect } from "react";

import { Toaster, toast } from "react-hot-toast";

import $ from "jquery";

import { fetchApi } from "../../defz";

import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";

import cancel from "../../assets/Icons/Cancel.svg";
import imgNotFound from "../../assets/Images/img-404.png";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

function Products() {
  let token = localStorage.getItem('ST');

  const [cover, setCover] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [cat, setCat] = useState("");
  const [products, setProducts] = useState("");
  const [productId, setProductId] = useState("");
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [catName, setCatName] = useState("0");
  const [catId, setCatId] = useState("");
  const [off, setOff] = useState("");
  const [dis, setDis] = useState("");

  useEffect(() => {
    fetchApi("/api/shop/get_cat", token).then((res) => {
      setCat(res.data);
    });
  }, []);

  useEffect(() => {
    fetchApi("/api/shop/get_product", token).then((res) => {
      setProducts(res.data);
    });
  }, []);

  const cancelCover = () => {
    setCover(false);
    setAdd(false);
    setDeleteProductModal(false);
  };

  const addHandler = () => {
    setCover(!cover);
    setAdd(!add);
  };

  const deleteProduct = (e) => {
    setCover(!cover);
    setDeleteProductModal(!deleteProductModal);
    setProductId(e.target.id);
  };

  const deleteProductHandler = (e) => {
    e.preventDefault();
    fetchApi("/api/shop/delet_product", token, {
      product_id: productId,
    }).then((res) => {
      if (res.description === "done!") {
        setCover(false);
        setDeleteProductModal(false);
        toast.success("محصول با موفقیت حذف شد");
        fetchApi("/api/shop/get_product", token).then((res) => {
          setProducts(res.data);
        });
      }
    });
  };

  const onSubmitProduct = (e) => {
    e.preventDefault();
    let nameCheck, priceCheck, offCheck, catNameCheck;

    name.length > 4 ? (nameCheck = true) : (nameCheck = false);
    nameCheck === false
      ? $("#productName").css({ border: "1px solid red" })
      : $("#productName").css({ border: "1px solid #707070" });

    price.length > 4 ? (priceCheck = true) : (priceCheck = false);
    priceCheck === false
      ? $("#price").css({ border: "1px solid red" })
      : $("#price").css({ border: "1px solid #707070" });

    off.length > 0 ? (offCheck = true) : (offCheck = false);
    offCheck === false
      ? $("#offMoney").css({ border: "1px solid red" })
      : $("#offMoney").css({ border: "1px solid #707070" });

    catName !== "0" ? (catNameCheck = true) : (catNameCheck = false);
    catNameCheck === false
      ? $("#cat").css({ border: "1px solid red" })
      : $("#cat").css({ border: "1px solid #707070" });

    if ((nameCheck, priceCheck, offCheck, catNameCheck)) {
      let body = {
        name: name,
        dis: dis,
        mony: price,
        offmony: off,
        cat_id: catId,
        cat_name: catName,
        img: urls,
      };
      fetchApi("/api/shop/add_product", token, body).then((res) => {
        if (res.description === "submited successfully!") {
          setCover(false);
          setAdd(false);
          toast.success("محصول جدید اضافه شد");
          fetchApi("/api/shop/get_product", token).then((res) => {
            setProducts(res.data);
          });
        }
      });
    }
  };

  return (
    <div className="category">
      <Toaster position="top-center" reverseOrder={false} />
      {cover ? <div className="cover" onClick={cancelCover}></div> : null}
      {add ? (
        <div className="addCat product-modal">
          <form action="" onSubmit={onSubmitProduct}>
            <label htmlFor="">عنوان محصول</label>
            <input
              className="inputProduct"
              id="productName"
              type="text"
              placeholder="محصول"
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="">هزینه</label>
            <input
              className="inputProduct"
              id="price"
              type="number"
              placeholder="25000"
              onChange={(e) => setPrice(e.target.value)}
            />
            <label htmlFor="">تخفیف</label>
            <input
              className="inputProduct"
              id="offMoney"
              type="number"
              placeholder="25000"
              onChange={(e) => setOff(e.target.value)}
            />
            <label htmlFor="">دسته بندی</label>
            <select
              name=""
              id="cat"
              onChange={(e) => {
                setCatId(e.target.children[e.target.selectedIndex].id);
                setCatName(e.target.children[e.target.selectedIndex].value);
              }}
            >
              <option value="0" id="0">
                انتخاب کنید
              </option>
              {cat
                ? cat.map((item, index) => {
                    return (
                      <option value={item.title} id={item._id} key={index}>
                        {item.title}
                      </option>
                    );
                  })
                : null}
            </select>
            <label htmlFor="dis">توضیحات</label>
            <textarea
              className="inputProduct"
              type="text"
              placeholder="محصول"
              onChange={(e) => {
                setDis(e.target.value);
              }}
            ></textarea>
            <label htmlFor="">آپلود عکس</label>
            <FilePond
              className="file-pond"
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              maxFiles={1}
              server={{
                process: async (
                  fieldName,
                  file,
                  metadata,
                  load,
                  error,
                  progress,
                  abort,
                  transfer,
                  options
                ) => {
                  const formData = new FormData();
                  formData.append("file", file);
                  const request = new XMLHttpRequest();
                  request.open(
                    "POST",
                    `https://evimv2.liara.run/api/shop/upload_image_product`
                  );
                  request.send(formData);
                  request.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                      let picReqJson = JSON.parse(request.responseText);
                      setUrls(picReqJson.description);
                    }
                  };
                  request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                      load(request.responseText);
                    } else {
                      error("oh no");
                    }
                  };
                },
              }}
              name="files"
              labelIdle='<span class="filepond--label-action">جهت انتخاب فایل کلیک کنید</span>'
              acceptedFileTypes={["image/*"]}
            />
            <div className="btns">
              <button>ثبت محصول</button>
            </div>
          </form>
        </div>
      ) : null}
      {deleteProductModal ? (
        <div className="addCat">
          <form action="">
            <input type="text" value="آیا از حذف محصول مطمئن هستید؟" disabled />
            <div className="btns">
              <button className="delete" onClick={deleteProductHandler}>
                حذف
              </button>
              <button onClick={cancelCover}>انصراف</button>
            </div>
          </form>
        </div>
      ) : null}
      <Header name="محصول" />
      <main className="body">
        <div className="button">
          <button onClick={addHandler}>افزودن محصول جدید +</button>
        </div>
        <div className="cats">
          {products
            ? products.map((item, index) => {
                console.log(item.img.length)
                return (
                  <div className="cat" key={index}>
                    <div className="img">
                      <img
                        src={item.img.length !== 0 ? item.img : imgNotFound}
                        alt="pic"
                      />
                      <div>
                        <span>{item.name}</span>
                        <span>{item.cat_name}</span>
                        <span>تبریز ، لاله</span>
                      </div>
                    </div>
                    <span>
                      <img
                        src={cancel}
                        alt="cancel"
                        id={item._id}
                        onClick={deleteProduct}
                      />
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </main>
      <Navigation active="productS" />
    </div>
  );
}

export default Products;
