import React, { useState, useEffect } from "react";
import "./Category.scss";

import { Toaster, toast } from "react-hot-toast";

import { fetchApi } from "../../defz";

import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";

import cancel from "../../assets/Icons/Cancel.svg";

import $ from "jquery";

function Category() {
  let token = localStorage.getItem('ST');
  
  const [cover, setCover] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteCatModal, setDeleteCatModal] = useState(false);
  const [cat, setCat] = useState("");
  const [catList, setCatList] = useState("");
  const [catId, setCatId] = useState("");

  useEffect(() => {
    fetchApi("/api/shop/get_cat", token).then((res) => {
      setCatList(res.data);
    });
  }, []);

  const cancelCover = () => {
    setCover(false);
    setAdd(false);
    setDeleteCatModal(false);
  };

  const addHandler = () => {
    setCover(!cover);
    setAdd(!add);
  };

  const deleteCat = (e) => {
    setCover(!cover);
    setDeleteCatModal(!deleteCatModal);
    setCatId(e.target.id);
  };

  const inputSubmit = (e) => {
    setCat(e.target.value);
  };

  const deleteCatHandler = (e) => {
    e.preventDefault();
    fetchApi("/api/shop/del_cat", token, {
      id: catId,
    }).then((res) => {
      if (res.description === "done!") {
        setCover(false);
        setAdd(false);
        setDeleteCatModal(false);
        toast.success("دسته بندی حذف شد");
        fetchApi("/api/shop/get_cat", token).then((res) => {
          setCatList(res.data);
        });
      }
    });
  };

  const submitCat = (e) => {
    e.preventDefault();
    if (cat.length > 4) {
      $("#titleCat").css({ border: "1px solid gray" });

      fetchApi("/api/shop/add_cat", token, {
        title: cat,
      }).then((res) => {
        if (res.description === "done!") {
          setCover(false);
          setAdd(false);
          setDeleteCatModal(false);
          toast.success("دسته بندی ثبت شد");

          fetchApi("/api/shop/get_cat", token).then((res) => {
            setCatList(res.data);
          });
        } else if (res.description === "is exist!") {
          toast.error("این دسته بندی موجود می باشد");
        }
      });
    } else {
      $("#titleCat").css({ border: "1px solid red" });
    }
  };

  return (
    <div className="category">
      <Toaster position="top-center" reverseOrder={false} />
      {cover ? <div className="cover" onClick={cancelCover}></div> : null}
      {add ? (
        <div className="addCat">
          <form action="">
            <input
              type="text"
              placeholder="عنوان دسته بندی"
              id="titleCat"
              onChange={inputSubmit}
            />
            <div className="btns">
              <button onClick={submitCat}>ثبت</button>
              <button onClick={addHandler}>انصراف</button>
            </div>
          </form>
        </div>
      ) : null}
      {deleteCatModal ? (
        <div className="addCat">
          <form action="">
            <input
              type="text"
              value="آیا از حذف دسته بندی مطمئن هستید؟"
              disabled
            />
            <div className="btns">
              <button className="delete" onClick={deleteCatHandler}>
                حذف
              </button>
              <button onClick={deleteCat}>انصراف</button>
            </div>
          </form>
        </div>
      ) : null}
      <Header name="دسته بندی" />
      <main className="body">
        <div className="button">
          <button onClick={addHandler}>افزودن +</button>
        </div>
        <div className="cats">
          {catList
            ? catList.map((item, index) => {
                return (
                  <div className="cat" key={index}>
                    <span>{item.title}</span>
                    <span>
                      <img
                        src={cancel}
                        alt="cancel"
                        onClick={deleteCat}
                        id={item._id}
                      />
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </main>
      <Navigation active="categoryS" />
    </div>
  );
}

export default Category;
